import * as React from "react"
//const content = "Experience International Hotels and Own Property in the Caribbean, Latin America and Beyond."

function Hero() {
	return (
    <div className="hero">
        <div className="hero-content">
            <h1>Experience International Hotels and <br/>Own Property in the Caribbean, Latin<br/> America and Beyond.</h1>
        </div>
	</div>

    );
}

export default Hero;