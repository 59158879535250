import * as React from "react"
import { Container } from "react-bootstrap"
import { Center } from '@mantine/core';
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql, Link } from "gatsby"

 



 
function Global({data}) {

    return (
        <StaticQuery 
        query={graphql`
        query CountryGlobal{
            allGraphCmsSectionGlobal { 
              edges {
                node {
                  id
                  heroTitle
                  heroButton{
                    ctaText
                    ctaUrl
                  }
                  itemCountries {
                    id
                    countryName
                    countryRegion
                    countryImage {
                      id
                      imageAltText
                      imagePhoto {
                        gatsbyImageData(placeholder: BLURRED, quality: 60)
                        url
                      }
                    }
                    countrySlug
                    countryDescription {
                      text
                      markdown
                      raw
                      html
                    }
                    countryGalleryImages {
                      id
                      imageAltText 
                      imagePhoto  {
                          gatsbyImageData(placeholder: BLURRED, quality: 60)
                      }
                    }
                  }
                }
              }
            }
          }
        `}
                
        render={data => (
    
    <div className="global">
    <div className="container">
        <div className="welcome-bg">

        </div>
        <div className="wrapper">
            

       

        


         <div className="gallery">
            <div className="image-gallery">

        

                <div className="title">
                <Container>
                
                 
                      
                      <h1>Explore Overseas Destinations & Opportunities</h1>
                      <div className="global-button">
                   {data.allGraphCmsSectionGlobal.edges.map(({ node: cta }) => (
                         <Link to={`${cta.heroButton.ctaUrl}`}><button type="button" id="button-two" class="btn btn-primary">{cta.heroButton.ctaText}</button></Link>
                         ))}
                         </div>
                  
               
                 
                 </Container>
               </div>
            
            <div className="photos">
             {data.allGraphCmsSectionGlobal.edges.map(({ node: countryMap }) => (
                <div  key={countryMap.id}>

                    {(countryMap.itemCountries || []).map((country, id)  => {
                    return !(country.countryRegion.includes('Latin_America') || country.countryRegion.includes('Caribbean') || country.countryRegion.includes('South_America') ) ?
                    <div className="main-content"  key={country.id}>
                        <div className="data-wrapper">
                        
                            <div className="estate-type">
                                <div className="image-field">
                                    <GatsbyImage image={country.countryImage.imagePhoto.gatsbyImageData}
                                        quality={60}
                                        formats={["auto", "webp", "avif"]}
                                        alt={country.countryImage.imageAltText}
                                    />
                                    <div className="overlay">
                                    <div className="overlay-content">
                                   
                                    <a href={`/${country.countrySlug}/` + 'realestate'}><button type="button" id="button-two" class="btn btn-primary">Buy Property</button></a>
                                    </div>
                                    
                                    </div>
                                </div>
                                <div className="desc-field">
                                    <div className="content">
                                        <Container> 
                                            <div className="block">
                                                <Center>
                                                    <div><h2>{country.countryName}</h2></div>
                                                    
                                                    
                                                </Center>
                                            </div>
                                        </Container>
                                    </div>
                                </div>
                            </div> 
                        
                        </div>
                        
                    </div> 
                    :null
                    })}
                </div>
                ))}
                </div>
                <div className="title">
                <Container>
                
                  {data.allGraphCmsSectionGlobal.edges.map(({ node: titleText }) => (
                      
                      <h1>{titleText.heroTitle}</h1>
                     
                  
                  ))}
                 
                </Container>
            </div>
                <div className="photos us">

             {data.allGraphCmsSectionGlobal.edges.map(({ node: countryMap }) => (
                <div  key={countryMap.id}>

                    {(countryMap.itemCountries || []).map((country, id)  => {
                    return (country.countryRegion.includes('South_America') ) ?
                    <div className="main-content"  key={country.id}>
                        <div className="data-wrapper">
                         <Link to={`/${country.countrySlug}/`}> 
                            <div className="estate-type">
                                <div className="image-field">
                                    <GatsbyImage image={country.countryImage.imagePhoto.gatsbyImageData}
                                        quality={60}
                                        formats={["auto", "webp", "avif"]}
                                        alt={country.countryImage.imageAltText}
                                    />
                                    <div className="overlay">
                                    <div className="overlay-content">
                                    
                                    <a href={`/${country.countrySlug}/`}><button type="button" id="button-two" class="btn btn-primary">Buy Property</button></a>
                                    </div>
                                    
                                    </div>
                                </div>
                                <div className="desc-field">
                                    <div className="content">
                                        <Container> 
                                            <div className="block">
                                                <Center>
                                                    <div><h2>{country.countryName}</h2></div>
                                                    
                                                    
                                                </Center>
                                            </div>
                                        </Container>
                                    </div>
                                </div>
                            </div> 
                        </Link>
                        </div>
                        
                    </div> 
                    :null
                    })}
                </div>
                ))}
                </div>


            
            </div>

            </div>
        </div>
	</div>
    </div>
    

    )}
      />
    );
}


export default Global;







