import * as React from "react"
import "./home/home.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "./home/components/hero-main"
import RealEstate from "./home/components/index-ctas"
import Global from "./home/components/global"
import Welcome from "./home/components/welcome"



const IndexPage = () => (
  
   <div>
     <h1>Redirecting.......</h1>
     </div>        
)

export default IndexPage
