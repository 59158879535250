import * as React from "react"
import { Container } from "react-bootstrap"
import { Center } from '@mantine/core';
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import MainLogo from "../../../images/logos/royal-palm-logo-main"

 



 
function RealEstate({data}) {

    return (
        <StaticQuery 
        query={graphql`
        query Countries{
            allGraphCmsSectionRealEstate { 
              edges {
                node {
                       
                  itemCountries {
                    countryImage {
                      id
                      imageAltText
                      imagePhoto { 

                        gatsbyImageData(placeholder: BLURRED, quality: 60)
                                     
                      }
                    }
                    id
                    countryName
                    countryRegion
                   
                    countrySlug
                    countryDescription {
                      text                       
                      raw
                      html       
                    }
                    countryGalleryImages {
                      id  
                      imageAltText       
                      imagePhoto  {          
                          gatsbyImageData(placeholder: BLURRED, quality: 60)
                      }
                    }
                  }
                  id
                  heroTitle
                }
              }
            }
          }                     
        `}
                
        render={data => (
        
    <div className="real-estate">
        <div className="welcome-bg">

        </div>
        <div className="wrapper">

            <div className="title">
                <Container>
                    <div className="rp-logo">
                        <MainLogo />
                    </div>
                    {data.allGraphCmsSectionRealEstate.edges.map(({ node: titleText }) => (
                      
                          <h1>{titleText.heroTitle}</h1>
                      
                      ))}
                   
                </Container>
            </div>

         <div className="gallery">
         <Container>

           <div id="bg-photos"></div>
            <div className="image-gallery">
            
              <div className="photos">
             {data.allGraphCmsSectionRealEstate.edges.map(({ node: countryMap }) => (
                <div  key={countryMap.id}>

                    {(countryMap.itemCountries || []).map((country, id)  => {
                    return (country.countryRegion.includes('Latin_America') || country.countryRegion.includes('Caribbean') ) ?
                    <div className="main-content"  key={country.id}>
                        <div className="data-wrapper">
                        
                            <div className="estate-type">
                                <div className="image-field">
                                    <GatsbyImage image={country.countryImage.imagePhoto.gatsbyImageData}
                                        quality={60}
                                        formats={["auto", "webp", "avif", "png", "jpg"]}
                                        alt={country.countryImage.imageAltText}
                                    />
                                    <div className="overlay">
                                   
                                   {!country.countryName.includes("Agro")
                                   ?
                                   <div className="overlay-content">
                                   <a href={`/${country.countrySlug}/` + 'hotels'}><button type="button" id="button-two" class="btn btn-primary">Book a Stay</button></a>
                                   <a href={`/${country.countrySlug}/` + 'realestate'}><button type="button" id="button-two" class="btn btn-primary">Buy Property</button></a>
                                   </div>
                                   :
                                   <div className="overlay-content">
                                  
                                   <a href={`/${country.countrySlug}/`}><button type="button" id="button-two" class="btn btn-primary">Buy Property</button></a>
                                   </div>
                                   }
                                
                                   
                                   </div>
                                </div>
                                <div className="desc-field">
                                    <div className="content">
                                        <Container> 
                                            <div className="block">
                                                <Center>
                                                    <div><h2>{country.countryName}</h2></div>
                                                    <div><span>{country.countryDescription.text}</span></div>
                                                    
                                                </Center>
                                            </div>
                                        </Container>
                                    </div>
                                </div>
                            </div> 
                     

                        </div>
                        
                    </div> 
                    :null
                    })}
                </div>
                ))}
                </div>
            
            </div>
            </Container>

            </div>
        </div>
	</div>
    

    )}
      />
    );
}


export default RealEstate;


