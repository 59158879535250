import * as React from "react"

function royalPalmLogoMain() {

	return (

        <div className="logo-svg">

<svg width="121" height="192" viewBox="0 0 121 192" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4336 79.9079V16.3438" stroke="#042827" stroke-width="3.63224"/>
<path d="M107.896 79.9079V16.3438" stroke="#042827" stroke-width="3.63224"/>
<path d="M24.357 28.9574C29.8054 25.3266 42.5182 23.2853 51.5988 28.9573C59.662 33.9938 60.5654 41.625 60.6667 48.3555C60.7679 41.625 61.6713 33.9938 69.7345 28.9573C78.8151 23.2853 91.5279 25.3266 96.9762 28.9574L68.6686 46.0219C73.1236 43.9657 78.3391 42.976 83.9312 45.9506C93.3836 50.9787 97.3936 60.6612 96.9734 67.195L65.3738 52.9771C68.0525 64.3876 73.7403 72.6468 84.262 72.6468H60.6808H60.6525H37.0713C47.593 72.6468 53.2808 64.3876 55.9595 52.9771L24.3599 67.195C23.9397 60.6612 27.9497 50.9787 37.4021 45.9506C42.9942 42.976 48.2097 43.9657 52.6647 46.0219L24.357 28.9574Z" fill="url(#paint0_linear_728_45)"/>
<path d="M20.7352 67.4268C20.2253 59.4976 24.959 48.4541 35.6964 42.7426C36.5633 42.2814 37.4231 41.8987 38.2737 41.5866L17.5766 29.1098L13.4336 26.5943V72.6457H20.8372L20.7352 67.4268Z" fill="#042827"/>
<path d="M100.595 67.4268C101.105 59.4976 96.371 48.4541 85.6337 42.7426C84.7668 42.2814 83.907 41.8987 83.0563 41.5866L103.753 29.1098L107.896 26.5943V72.6457H100.493L100.595 67.4268Z" fill="#042827"/>
<path d="M38.8593 72.6435H38.8574H13.4336V79.908C20.8808 79.908 27.2811 84.3905 30.0836 90.8047H38.8574H38.8593H60.6499H82.4434H91.2191C94.0216 84.3905 100.422 79.908 107.869 79.908V72.6435H82.4434H60.6499H38.8593Z" fill="url(#paint1_linear_728_45)"/>
<rect x="11.9707" y="7.26367" width="15.631" height="15.631" transform="rotate(45 11.9707 7.26367)" fill="url(#paint2_linear_728_45)"/>
<rect width="15.631" height="15.631" transform="matrix(-0.707107 0.707107 0.707107 0.707107 109.432 7.26367)" fill="url(#paint3_linear_728_45)"/>
<path d="M60.6519 101.703H20.6973V105.335H27.9617V108.968H53.3874L60.6519 101.703Z" fill="#134748"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.9617 108.968V105.335H20.6973V101.703H60.6519L53.3874 108.968H27.9617Z" fill="#134748"/>
<path d="M60.6782 101.703H100.633V105.335H93.3683V108.968H67.9427L60.6782 101.703Z" fill="#134748"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M93.3683 108.968V105.335H100.633V101.703H60.6782L67.9427 108.968H93.3683Z" fill="#134748"/>
<rect x="60.8066" width="15.631" height="15.631" transform="rotate(45 60.8066 0)" fill="url(#paint4_linear_728_45)"/>
<path d="M20.6973 94.4375H60.6508H100.605V101.702H60.6508H20.6973V94.4375Z" fill="url(#paint5_linear_728_45)"/>
<path d="M4 159H13.8639V146.185C17.194 147.829 11.7983 159.042 22.2523 159.042C23.8963 159.042 25.2874 158.663 26.4255 157.862C28.6175 156.344 29.2076 154.321 29.2076 152.677C29.2076 152.255 29.1233 151.581 28.9547 150.653C28.7861 149.768 28.1959 148.714 27.1421 147.576C26.6363 149.389 25.0345 150.569 23.0533 150.569C22.5896 150.569 22.168 150.527 21.7887 150.4C19.7232 149.852 20.777 146.143 17.3204 145.005C17.6155 145.047 17.9106 145.089 18.2056 145.089C19.5967 145.089 21.3671 144.583 22.9268 143.192C24.4443 141.843 25.5403 139.778 25.5403 137.291V137.249C25.5403 132.865 23.0111 129.492 17.6576 129.492H4V159ZM16.3087 137.502C16.1823 142.939 15.3814 143.656 13.8639 143.656V130.799C13.906 130.799 13.906 130.799 13.9482 130.799C15.5078 130.799 16.3087 132.907 16.3087 137.08C16.3087 137.207 16.3087 137.375 16.3087 137.502Z" fill="url(#paint6_linear_728_45)"/>
<path d="M29.2979 144.625C29.34 148.335 30.8575 152.129 33.2181 154.953C35.5787 157.819 38.7402 159.59 42.0703 159.59C42.1967 159.59 42.2811 159.59 42.4075 159.59C50.1637 159.379 54.7584 150.991 54.7584 144.288C54.7584 144.204 54.7584 144.12 54.7584 144.035C54.6319 140.284 53.4517 136.448 51.2597 133.581C49.0677 130.715 45.8219 128.902 41.9017 128.902C41.8174 128.902 41.7752 128.902 41.6909 128.902C33.0495 129.113 29.2979 136.701 29.2979 143.993C29.2979 144.204 29.2979 144.415 29.2979 144.625ZM36.0002 132.443C36.0424 131.811 36.211 131.389 36.5904 131.263C36.6747 131.221 36.759 131.221 36.8854 131.221C37.7707 131.221 39.3725 133.076 41.0165 136.153C42.5761 139.019 43.925 142.265 44.4309 143.361C44.7259 144.035 45.3161 145.721 45.9905 147.618C46.9601 150.611 47.8031 153.941 47.8031 155.459C47.8031 155.754 47.8874 156.766 47.1708 157.061C47.0865 157.103 47.0022 157.103 46.8758 157.103C46.2856 157.103 45.4847 156.386 44.473 154.869C42.9555 152.677 41.3537 149.178 40.4263 146.944C40.0891 146.185 39.8362 145.637 39.7097 145.258C39.4146 144.541 38.698 142.855 38.0236 140.874C36.9276 137.839 36.0002 134.424 36.0002 132.738C36.0002 132.612 36.0002 132.528 36.0002 132.443Z" fill="url(#paint7_linear_728_45)"/>
<path d="M52.3395 129.492C53.4777 131.98 55.5853 136.321 57.3979 140.157C58.4518 142.391 59.5477 144.71 60.6859 147.155V159H70.5497V147.155L70.8027 146.649C70.9713 146.312 71.182 145.848 71.4771 145.216C73.2897 141.422 77.2943 132.865 78.9804 129.492H68.9479C69.3273 130.083 69.7067 130.884 70.1282 131.853C70.9713 133.876 71.182 136.068 71.182 137.586C71.182 138.387 71.0977 139.483 70.9713 140.832C70.8026 142.181 70.2547 143.951 69.3273 146.143C68.0205 143.403 66.8402 140.874 65.7443 138.555C63.8474 134.635 61.8662 130.504 61.4025 129.492H52.3395Z" fill="url(#paint8_linear_728_45)"/>
<path d="M73.6299 154.279C74.0093 155.459 74.9366 156.555 76.3698 157.524C77.7609 158.494 79.2363 159 80.7116 159C82.5664 159 84.2103 158.199 85.3906 156.85C86.5709 155.501 87.0768 153.773 87.0768 152.087C87.0768 151.876 87.0346 151.623 87.0346 151.37C86.4023 151.623 85.6857 151.749 84.9269 151.749C83.7888 151.749 82.5664 151.412 81.5125 150.569C78.8147 148.419 79.5313 145.89 80.8802 142.138C81.7655 145.679 83.8731 146.143 86.7395 146.269L89.7324 159H99.259C99.259 158.789 97.2778 151.412 95.4652 144.078C94.3271 139.778 93.1047 134.93 91.7136 129.492H83.0722C81.8919 133.834 79.8264 137.67 77.8874 141.169C76.9178 142.897 75.9905 144.541 75.1896 146.101C73.5456 149.178 73.2927 151.075 73.2927 152.213C73.2927 152.93 73.4191 153.604 73.6299 154.279ZM81.639 140.157C82.4399 138.007 83.1565 135.563 83.5359 132.696L86.1494 143.909C83.6624 143.782 82.5242 143.192 81.639 140.157Z" fill="url(#paint9_linear_728_45)"/>
<path d="M118.259 159V146.269C116.742 150.316 114.086 152.761 111.388 152.761H111.22V129.492H101.356V159H118.259Z" fill="url(#paint10_linear_728_45)"/>
<path d="M10.6055 191.999H20.4693V179.396C21.1016 177.752 22.6191 177.583 24.6003 177.583C26.4551 177.583 27.5511 177.625 27.6354 177.625C30.4175 177.625 33.3261 176.614 33.6633 171.471C33.6633 171.134 33.7054 170.839 33.7054 170.501C33.7054 167.803 32.8624 165.654 30.8812 164.305C28.9 162.956 25.9914 162.492 22.6191 162.492H10.6055V191.999ZM20.4693 177.246V167.593C20.8909 167.508 21.1859 167.466 21.3545 167.466C22.9985 167.466 23.7994 169.237 23.7994 171.935C23.7994 172.061 23.7994 172.145 23.7994 172.23C23.7994 176.403 21.1438 174.927 20.4693 177.246Z" fill="#134748"/>
<path d="M31.084 187.278C31.4634 188.459 32.3908 189.555 33.824 190.524C35.215 191.494 36.6904 191.999 38.1658 191.999C40.0205 191.999 41.6645 191.199 42.8448 189.85C44.0251 188.501 44.5309 186.772 44.5309 185.086C44.5309 184.876 44.4888 184.623 44.4888 184.37C43.8565 184.623 43.1399 184.749 42.3811 184.749C41.243 184.749 40.0205 184.412 38.9667 183.569C36.2689 181.419 36.9855 178.89 38.3344 175.138C39.2196 178.679 41.3273 179.143 44.1937 179.269L47.1866 191.999H56.7132C56.7132 191.789 54.732 184.412 52.9194 177.077C51.7813 172.778 50.5588 167.93 49.1678 162.492H40.5264C39.3461 166.834 37.2806 170.67 35.3415 174.169C34.372 175.897 33.4446 177.541 32.6437 179.101C30.9997 182.178 30.7468 184.075 30.7468 185.213C30.7468 185.929 30.8733 186.604 31.084 187.278ZM39.0931 173.157C39.8941 171.007 40.6107 168.562 40.99 165.696L43.6035 176.909C41.1165 176.782 39.9784 176.192 39.0931 173.157Z" fill="#134748"/>
<path d="M75.7134 191.999V179.269C74.1959 183.316 71.5403 185.761 68.8425 185.761H68.6738V162.492H58.81V191.999H75.7134Z" fill="#134748"/>
<path d="M77.8283 191.999H87.102C83.1818 188.079 79.4302 182.641 79.3037 166.202C81.58 172.314 85.8796 183.78 87.7765 188.922C87.8608 188.922 90.8958 182.979 94.1838 177.246C96.0385 173.831 98.1462 169.995 100.549 165.696V191.999H110.413V162.492H100.549L92.7506 176.445C90.4321 170.417 87.903 164.305 87.2285 162.492H77.8283V191.999Z" fill="#134748"/>
<defs>
<linearGradient id="paint0_linear_728_45" x1="60.6666" y1="25.4277" x2="60.6666" y2="72.6468" gradientUnits="userSpaceOnUse">
<stop stop-color="#134748"/>
<stop offset="1" stop-color="#042827"/>
</linearGradient>
<linearGradient id="paint1_linear_728_45" x1="60.6514" y1="90.8047" x2="60.6514" y2="72.6435" gradientUnits="userSpaceOnUse">
<stop stop-color="#042827"/>
<stop offset="1" stop-color="#134748"/>
</linearGradient>
<linearGradient id="paint2_linear_728_45" x1="19.7862" y1="7.26367" x2="19.7862" y2="22.8947" gradientUnits="userSpaceOnUse">
<stop stop-color="#E3C499"/>
<stop offset="1" stop-color="#BE9874"/>
</linearGradient>
<linearGradient id="paint3_linear_728_45" x1="7.8155" y1="0" x2="7.8155" y2="15.631" gradientUnits="userSpaceOnUse">
<stop stop-color="#E3C499"/>
<stop offset="1" stop-color="#BE9874"/>
</linearGradient>
<linearGradient id="paint4_linear_728_45" x1="60.9434" y1="-0.136726" x2="76.4997" y2="15.4196" gradientUnits="userSpaceOnUse">
<stop stop-color="#E3C499"/>
<stop offset="1" stop-color="#BE9874"/>
</linearGradient>
<linearGradient id="paint5_linear_728_45" x1="60.6514" y1="94.4375" x2="60.6514" y2="101.702" gradientUnits="userSpaceOnUse">
<stop stop-color="#042827"/>
<stop offset="1" stop-color="#134748"/>
</linearGradient>
<linearGradient id="paint6_linear_728_45" x1="61.1296" y1="128.902" x2="61.1296" y2="159.59" gradientUnits="userSpaceOnUse">
<stop stop-color="#E3C499"/>
<stop offset="1" stop-color="#BE9874"/>
</linearGradient>
<linearGradient id="paint7_linear_728_45" x1="61.1296" y1="128.902" x2="61.1296" y2="159.59" gradientUnits="userSpaceOnUse">
<stop stop-color="#E3C499"/>
<stop offset="1" stop-color="#BE9874"/>
</linearGradient>
<linearGradient id="paint8_linear_728_45" x1="61.1296" y1="128.902" x2="61.1296" y2="159.59" gradientUnits="userSpaceOnUse">
<stop stop-color="#E3C499"/>
<stop offset="1" stop-color="#BE9874"/>
</linearGradient>
<linearGradient id="paint9_linear_728_45" x1="61.1296" y1="128.902" x2="61.1296" y2="159.59" gradientUnits="userSpaceOnUse">
<stop stop-color="#E3C499"/>
<stop offset="1" stop-color="#BE9874"/>
</linearGradient>
<linearGradient id="paint10_linear_728_45" x1="61.1296" y1="128.902" x2="61.1296" y2="159.59" gradientUnits="userSpaceOnUse">
<stop stop-color="#E3C499"/>
<stop offset="1" stop-color="#BE9874"/>
</linearGradient>
</defs>
</svg>


        </div>

    );
}

export default royalPalmLogoMain;